<template>
  <div>
    <a-modal
      :visible="visible"
      title="添加跟进记录"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :maskClosable="false"
      @cancel="handleCancel"
      @ok="handleConfirm"
    >
      <a-form :form="dataForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="跟进日期">
          <a-date-picker
            v-decorator="[
              'follow_up_date',
              { initialValue: moment().format('YYYY-MM-DD'), rules: [{ required: true, message: '请选择跟进日期' }] },
            ]"
            valueFormat="YYYY-MM-DD"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item label="跟进方式">
          <a-select
            v-decorator="['method', { initialValue: 'phone', rules: [{ required: true, message: '请选择跟进方式' }] }]"
            :allowClear="true"
            style="width: 100%"
          >
            <a-select-option key="phone" value="phone">电话联系</a-select-option>
            <a-select-option key="first_visit" value="first_visit">一访</a-select-option>
            <a-select-option key="second_visit" value="second_visit">再访</a-select-option>
            <a-select-option key="wechat" value="wechat">微信</a-select-option>
            <a-select-option key="others" value="others">其他</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="内容">
          <a-textarea
            v-decorator="['content', { rules: [{ required: true, message: '请输入跟进内容' }] }]"
            :auto-size="{ minRows: 4 }"
            :allowClear="true"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { leadFollowUpRecordCreate } from "@/apis/data";
import moment from "moment";

export default {
  props: ["visible", "item"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      moment,
      confirmLoading: false,
      dataForm: null,
    };
  },
  methods: {
    handleConfirm() {
      this.dataForm.validateFields((error, values) => {
        if (error === null) {
          this.confirmLoading = true;
          leadFollowUpRecordCreate({ lead: this.item.id, ...values })
            .then((data) => {
              this.$emit("add", data);
              this.$message.success("添加成功");
              this.handleCancel();
            })
            .catch((error) => {
              const errorFields = Object.entries(error).reduce((acc, [field, errors]) => {
                acc[field] = { value: values[field], errors: errors.map((value) => new Error(value)) };
                return acc;
              }, {});
              this.dataForm.setFields(errorFields);
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
    handleCancel() {
      this.$emit("cancel", false);
    },
  },
  mounted() {
    this.dataForm = this.$form.createForm(this);
  },
};
</script>

<style scoped></style>
